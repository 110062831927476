@import url("https://fonts.googleapis.com/css2?family=Public+Sans:wght@400;700&display=swap");

:root {
  --color-orange: #ff5631;
  --color-blue200: #00b8d9;
  --color-black800: #212b36;
  --color-gray200: #f2f3f5;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: #fafafb;
  font-family: "Public Sans", sans-serif;
}

.custom-date-picker .react-datepicker__input-container input::placeholder {
  color: #000000;
}

.custom-date-picker .react-datepicker {
  width: 100%;
  max-width: 300px;
  box-sizing: border-box;
  overflow: hidden;
  z-index: 10;
}

.custom-date-picker .react-datepicker__month-container {
  width: 100%;
  overflow: hidden;
}

.custom-date-picker .react-datepicker-popper {
  max-width: 300px;
  overflow: hidden;
}

.custom-date-picker .react-datepicker__input-container input {
  display: none;
}

.custom-input {
  background-color: #f0f0f0;
  border: none;
  padding: 6px 12px;
  font-size: 11px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
}

.custom-input span {
  flex: 1;
}

.custom-input svg {
  margin-left: 8px;
}

/* Custom styles for the time picker */
.custom-time-picker .react-time-picker__inputGroup__input {
  font-size: 10px !important;
  /* Set font size to 8px */
  letter-spacing: normal !important;
  /* Ensures normal letter spacing */
  font-family: inherit;
  /* Use the inherited font from the parent */
  /* padding: 0 !important; */
}

.custom-time-picker .react-time-picker__inputGroup__input::placeholder {
  font-size: 10px !important;
}

.custom-time-picker .react-time-picker__inputGroup {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.custom-time-picker .react-time-picker__wrapper {
  border-radius: 6px !important;
  border: 1px solid #ccc;
  padding: 5px;
}

.custom-time-picker .react-time-picker__clear-button,
.custom-time-picker__clock-button {
  display: none;
  /* Hide clear and clock buttons if not needed */
}

.custom-time-picker .react-time-picker__inputGroup__leadingZero {
  font-size: 10px;
}


.dalierNumber .MuiOutlinedInput-input {
  text-align: center !important;
}


/* Muhammad Usman Start */
::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: #E6E6E6;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background: #f8f9fa;
  border-radius: 10px;
}
::-webkit-scrollbar:horizontal {
  height: 7px; 
}
/* Muhammad Usman End */

.order-field{
  width: 100%;
  border: 1px solid #4E5150;
  color: #4E5150;
  font-weight: 400;
  padding: 8px 12px;
  height: 36px
}
